import { Environment } from '../app/core/models/environment/environment.model';

export const environment: Environment = {
  production: '#{fe-environment-production}#',
  // eslint-disable-next-line @typescript-eslint/no-require-imports,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-var-requires,@typescript-eslint/restrict-plus-operands,@typescript-eslint/no-unsafe-assignment,@typescript-eslint/restrict-template-expressions,@typescript-eslint/no-unsafe-call
  appVersion: `${require('../../package.json').version}`,
  feEnvironmentOperatorAppUrl: '#{fe-environment-operator-app-url}#',
  feEnvironmentBackendUrl: '#{fe-environment-backend-url}#',
  feEnvironmentBackendScope: '#{fe-environment-operator-backend-scope}#',
  feEnvironmentB2cClientId: '#{fe-environment-operator-b2c-client-id}#',
  feEnvironmentB2cTenant: '#{fe-environment-operator-b2c-tenant}#',
  feEnvironmentB2cTenantName: '#{fe-environment-operator-b2c-tenant-name}#',
  feEnvironmentB2cPolicySignUpSignIn:
    '#{fe-environment-operator-b2c-policy-sign-up-sign-in}#',
  feEnvironmentLoggingApplicationInsightsKey:
    '#{fe-environment-logging-application-insights-key}#',
  feEnvironmentLoggingConsole: '#{fe-environment-logging-console}#',
  feEnvironmentIdleTimeoutMinutes: '#{fe-environment-idle-timeout-minutes}#',
  feBrandingContentOperatorHelpUrl: '#{fe-branding-content-operator-help-url}#',
  feBrandingContentOperatorImpressumUrl:
    '#{fe-branding-content-operator-impressum-url}#',
  feBrandingContentOperatorPageTitle:
    '#{fe-branding-content-operator-page-title}#',
  feEnvironmentOperatorTableShortPageReport:
    '#{fe-environment-operator-table-short-page-report}#',
  feEnvironmentOperatorTableShortEntityCount:
    '#{fe-environment-operator-table-short-entity-count}#',
  feEnvironmentFeaturePowerBILink: '#{fe-Environment-Feature-PowerBI-Link}#',
  feEnvironmentIsNbb: '#{fe-environment-is-nbb}#',
  feEnvironmentFeaturePlausibilityCheck:
    '#{global-feature-plausibility-check-enabled}#',
  feEnvironmentFeatureFederalSupplyShortageOverride:
    '#{fe-environment-feature-federal-supply-shortage-override}#',
  feEnvironmentOperatorNetworkCode: '#{fe-environment-operator-network-code}#',
  feEnvironmentStage: '#{wgi-feature-flags-stage}#',
  feEnvironmentAppConfigurationReadonlyConnectionString:
    '#{wgi-feature-flags-readonly-connection-string}#',
  feEnvironmentKeyPrefix: '#{wgi-feature-flags-key-prefix}#',
  feEnvironmentPubSubHub: '#{wgi-pubsub-hub}#',
};
